/* number sections on certain pages */

#faq {
	counter-reset: heading;
}
#faq h2:before {
    color: $grey-dk-000;
	content: counter(heading)"  ";
	counter-increment: heading;
}
#faq h2 {
	counter-reset: subheading;
}
#faq h3:before {
    color: $grey-dk-000;
	content: counter(heading)"." counter(subheading, lower-alpha)"  ";
	counter-increment: subheading;
}

#text-only-logo {
    font-weight: bold;
    font-size: 45px;
    color: $gap-blue;
}

.breadcrumb-nav {
  margin-top: 0rem;
  margin-bottom: -$sp-4;
}

.main-content-wrap {
  padding-top: 1rem;
}
