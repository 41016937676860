@import "./color_schemes/light";

$gap-blue: #0000cc;

$body-background-color: $white !default;
$body-heading-color: $grey-dk-300 !default;
$body-text-color: $grey-dk-100 !default;
$link-color: $gap-blue;
$nav-child-link-color: $grey-dk-100;
$sidebar-color: #ccffff;
$base-button-color: #f7f7f7 !default;
$btn-primary-color: $gap-blue;
$code-background-color: $grey-lt-000 !default;
$feedback-color: darken($sidebar-color, 20%);
$table-background-color: $white !default;
$search-background-color: $white !default;
$search-result-preview-color: $grey-dk-000 !default;

footer.site-footer {
  color: $grey-dk-100;
}
